import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { bool } from 'yup';

export type PortState = 'closed' | 'closing' | 'open' | 'opening';

export type SerialMessage = {
    value: string;
    timestamp: number;
};

type SerialMessageCallback = (message: SerialMessage) => void;

export interface SerialContextValue {
    canUseSerial: boolean;
    hasTriedAutoconnect: boolean;
    portState: PortState;
    connect(): Promise<boolean>;
    disconnect(): void;
    subscribe(callback: SerialMessageCallback): () => void;
    serialData: number;
    isAutoDisconnected: boolean;
    scale: string;
    setScale: React.Dispatch<React.SetStateAction<string>>;
    isStable: boolean;
}
export const SerialContext = createContext<SerialContextValue>({
    canUseSerial: false,
    hasTriedAutoconnect: false,
    connect: () => Promise.resolve(false),
    disconnect: () => {},
    portState: 'closed',
    subscribe: () => () => {},
    serialData: 0,
    isAutoDisconnected: false,
    scale: 'cas',
    setScale: () => {},
    isStable: false,
});

declare const navigator: any;

export const useSerial = () => useContext(SerialContext);

// interface SerialProviderProps {}
function SerialProvider({ children }: PropsWithChildren) {
    const [canUseSerial] = useState(() => 'serial' in navigator);
    const [portState, setPortState] = useState<PortState>('closed');
    const [hasTriedAutoconnect, setHasTriedAutoconnect] = useState(false);
    const [hasManuallyDisconnected, setHasManuallyDisconnected] = useState(false);
    const [serialData, setSerialData] = useState<number>(0);
    const serialDataRef = useRef<number>(0);
    const [isAutoDisconnected, setIsAutoDisconnected] = useState(false);
    const [scale, setScale] = useState('cas');
    const [isStable, setIsStable] = useState<boolean>(false); // 안정성 상태 추가

    const portRef = useRef<any>(null);
    const readerRef = useRef<any | null>(null);
    const readerClosedPromiseRef = useRef<Promise<void>>(Promise.resolve());

    const currentSubscriberIdRef = useRef<number>(0);
    const subscribersRef = useRef<Map<number, SerialMessageCallback>>(new Map());
    /**
     * Subscribes a callback function to the message event.
     *
     * @param callback the callback function to subscribe
     * @returns an unsubscribe function
     */
    const subscribe = (callback: SerialMessageCallback) => {
        const id = currentSubscriberIdRef.current;
        subscribersRef.current.set(id, callback);
        currentSubscriberIdRef.current += 1;

        return () => {
            subscribersRef.current.delete(id);
        };
    };

    /**
     * Reads from the given port until it's been closed.
     *
     * @param port the port to read from
     */

    let buffer = ''; // 데이터를 누적할 버퍼
    let lastUpdated = Date.now();

    const readUntilClosed = async (port: any) => {
        if (port.readable) {
            const textDecoder = new TextDecoderStream();
            const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
            readerRef.current = textDecoder.readable.getReader();

            try {
                while (true) {
                    // eslint-disable-next-line no-await-in-loop
                    const { value, done } = await readerRef.current.read();

                    if (done) {
                        break;
                    }

                    if (scale === 'and') {
                        buffer += value; // 새 데이터 누적
                        if (buffer.includes('\r\n')) {
                            const trimmedMessage = buffer.trim();
                            // console.log(trimmedMessage);
                            buffer = '';
                            const regex = /^(ST|US|OL),\+([0-9.]+)\s(kg|g|lb|oz)$/;

                            if (regex.test(trimmedMessage)) {
                                const dataArray = regex.exec(trimmedMessage);
                                if (dataArray) {
                                    serialDataRef.current = parseFloat(dataArray[2]);
                                    setIsStable(dataArray[1] === 'ST');
                                } else {
                                    serialDataRef.current = 0; // 기본값 설정
                                    setIsStable(false); // 안정성 상태 초기화
                                }
                            }
                        }
                    } else if (scale === 'cas') {
                        const regex =
                            /(?:OL|ST|US),(?:NT|GS),([+-]((?=\s*[0-9]+\.[0-9]+)[.0-9\s]{7}|[\s0-9]{1,7})\s(g\s\s|lb\s|kg\s|oz\s))/;

                        if (regex.test(value)) {
                            const dataArray = regex.exec(value);
                            serialDataRef.current = dataArray ? parseFloat(dataArray[2]) : 0; // 빠르게 갱신
                        }
                    }

                    if (Date.now() - lastUpdated >= 16) {
                        setSerialData(serialDataRef.current); // 상태 업데이트
                        lastUpdated = Date.now();
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                readerRef.current.releaseLock();
                buffer = '';
            }
            await readableStreamClosed.catch(() => {}); // Ignore the error
        }
    };

    const openPort = async (port: any) => {
        try {
            if (scale === 'and') {
                await port.open({
                    baudRate: 9600,
                    dataBits: 7,
                    stopBits: 1,
                    parity: 'even',
                });
            } else {
                await port.open({ baudRate: 9600 });
            }
            portRef.current = port;
            setPortState('open');
            setHasManuallyDisconnected(false);
        } catch (error) {
            setPortState('closed');
            console.error('Could not open port');
        }
    };

    const manualConnectToPort = async () => {
        if (canUseSerial && portState === 'closed') {
            setPortState('opening');
            const filters = [
                { usbVendorId: 0x067b, usbProductId: 0x2303 },
                { usbVendorId: 0x0403, usbProductId: 0x6001 },
            ];
            try {
                const ports = await navigator.serial.getPorts();
                if (!ports.length) {
                    const port = await navigator.serial.requestPort({ filters });
                    await openPort(port);
                } else {
                    await openPort(ports[0]);
                }
                setIsAutoDisconnected(false); // 여기에 상태 리셋 추가
                return true;
            } catch (error) {
                setPortState('closed');
                console.error('User did not select port');
            }
        }
        return false;
    };

    const autoConnectToPort = async () => {
        if (canUseSerial && portState === 'closed') {
            setPortState('opening');
            const availablePorts = await navigator.serial.getPorts();
            if (availablePorts.length) {
                const port = availablePorts[0];
                await openPort(port);
                return true;
            }
            setPortState('closed');
            setHasTriedAutoconnect(true);
        }
        return false;
    };

    const manualDisconnectFromPort = async () => {
        if (canUseSerial && portState === 'open') {
            const port = portRef.current;
            if (port) {
                setPortState('closing');
                // Cancel any reading from port
                readerRef.current?.cancel();
                await readerClosedPromiseRef.current;
                readerRef.current = null;
                // Close and nullify the port
                await port.close();
                portRef.current = null;
                // Update port state

                setHasManuallyDisconnected(true);
                setHasTriedAutoconnect(false);
                setPortState('closed');
                setSerialData(0);
                setIsAutoDisconnected(false);
            }
        }
    };
    /*
     * Event handler for when the port is disconnected unexpectedly.
     */
    const onPortDisconnect = async () => {
        // Wait for the reader to finish it's current loop
        await readerClosedPromiseRef.current;
        // Update state
        readerRef.current = null;
        readerClosedPromiseRef.current = Promise.resolve();
        portRef.current = null;
        setHasTriedAutoconnect(false);
        setPortState('closed');
        setSerialData(0);
        setIsAutoDisconnected(true); // 자동 연결 해제 상태 설정
    };

    // Handles attaching the reader and disconnect listener when the port is open
    useEffect(() => {
        const port = portRef.current;
        if (portState === 'open' && port) {
            // When the port is open, read until closed
            const aborted = { current: false };
            readerRef.current?.cancel();
            readerClosedPromiseRef.current.then(() => {
                if (!aborted.current) {
                    readerRef.current = null;
                    readerClosedPromiseRef.current = readUntilClosed(port);
                }
            });
            // Attach a listener for when the device is disconnected
            navigator.serial.addEventListener('disconnect', onPortDisconnect);
            return () => {
                aborted.current = true;
                navigator.serial.removeEventListener('disconnect', onPortDisconnect);
            };
        }
    }, [portState]);

    // Tries to auto-connect to a port, if possible
    // useEffect(() => {
    //     if (canUseSerial && !hasManuallyDisconnected && !hasTriedAutoconnect && portState === 'closed') {
    //         autoConnectToPort();
    //     }
    // }, [autoConnectToPort, canUseSerial, hasManuallyDisconnected, hasTriedAutoconnect, portState]);

    const serialContextProviderValue = useMemo(
        () => ({
            canUseSerial,
            hasTriedAutoconnect,
            subscribe,
            portState,
            connect: manualConnectToPort,
            disconnect: manualDisconnectFromPort,
            serialData,
            isAutoDisconnected,
            scale,
            setScale,
            isStable,
        }),
        [
            canUseSerial,
            serialData,
            hasTriedAutoconnect,
            manualConnectToPort,
            manualDisconnectFromPort,
            portState,
            isAutoDisconnected,
            scale,
            setScale,
            isStable,
        ]
    );

    return <SerialContext.Provider value={serialContextProviderValue}>{children}</SerialContext.Provider>;
}

export default SerialProvider;
